<script lang="ts">
import { project as barberTools } from '~/pages/projekt/barber-tools.vue'
import { project as echtJetzt } from '~/pages/projekt/echt-jetzt.vue'
import { project as falcondev } from '~/pages/projekt/falcondev.vue'
import { project as gruenderRakete } from '~/pages/projekt/gruender-rakete.vue'
import { project as hybridTour } from '~/pages/projekt/hybrid-tour.vue'
import { project as klassikDeluxe } from '~/pages/projekt/klassik-deluxe.vue'
import { project as leaderforum } from '~/pages/projekt/leaderforum.vue'
import { project as legaleos } from '~/pages/projekt/legaleos.vue'
import { project as manulaEis } from '~/pages/projekt/manula-eis.vue'
import { project as medicalIllustration } from '~/pages/projekt/medical-illustration.vue'
import { project as microsoftKleinevents } from '~/pages/projekt/microsoft-kleinevents.vue'
import { project as microsoftMbs } from '~/pages/projekt/microsoft-mbs.vue'
import { project as monsterFamily } from '~/pages/projekt/monster-family.vue'
import { project as ocHa } from '~/pages/projekt/oc-ha.vue'
import { project as startupCamp } from '~/pages/projekt/startup-camp.vue'

export const allProjects = [
  [ocHa, 'fluid'],
  [startupCamp, 'right'],
  [klassikDeluxe, 'left'],
  [barberTools, 'fluid'],
  [manulaEis, 'fluid'],
  [leaderforum, 'left'],
  [legaleos, 'right'],
  [microsoftKleinevents, 'fluid'],
  [falcondev, 'fluid'],
  [microsoftMbs, 'right'],
  [echtJetzt, 'left'],
  [medicalIllustration, 'fluid'],
  // following are missing on design:
  [gruenderRakete, 'fluid'],
  [hybridTour, 'left'],
  [monsterFamily, 'right'],
] as const
</script>

<script lang="ts" setup>
useSeoMeta({
  title: 'Projekte',
})
</script>

<template>
  <div class="flex flex-col items-center gap-6">
    <Padding class="mt-44 w-full">
      <Container class="mx-auto">
        <PageHeadline>Projekte</PageHeadline>
      </Container>
    </Padding>
    <Container class="gap-10 md:gap-16 xl:gap-20">
      <ProjectSection
        v-for="[project, alignment] of allProjects"
        :key="project.slug"
        :alignment="alignment"
        :project="project"
        class="-mt-tilt first:mt-0"
      />
    </Container>
  </div>
</template>
